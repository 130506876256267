<script>
import FormTelNumbers from '@components/form-tel-numbers'

export default {
    components: { FormTelNumbers },
    props: {
        field: {
            type: Object,
            required: true,
        },
        maxlength: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            number: '07',
            focusNumber: false,
        }
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: (event) => this.$emit('input', event.target.value),
            }
        },
        activeNumber() {
            return this.number.length
        },
    },
    watch: {
        activeNumber: function (newValue) {
            this.syncInputToActiveNumber(newValue)
        },
    },
    methods: {
        onKeydown(event) {
            if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
                if (this.number.length + 1 <= this.maxlength) {
                    this.number = this.number + event.key
                }
                if (this.number.length === this.maxlength) {
                    this.$refs.input.blur()
                }
            }
        },
        onKeyup(event) {
            if (event.keyCode === 8 && this.number.length - 1 >= 0) {
                this.number = this.number.slice(0, -1)
            }
        },
        onFocus() {
            this.syncInputToActiveNumber(this.activeNumber)
            this.focusNumber = true
        },
        onBlur() {
            this.removeInputStyles(this.$refs.input)
            this.focusNumber = false
        },
        syncInputToActiveNumber(value) {
            let numbers = document.getElementsByClassName('number')
            let number = numbers[value]
            if (typeof number !== 'undefined') {
                let infos = number.getBoundingClientRect()
                this.$refs.input.style['left'] = `${number.offsetLeft}px`
                this.$refs.input.style['top'] = `${number.offsetTop}px`
                this.$refs.input.style['width'] = `${infos.width}px`
                this.$refs.input.style['height'] = `${infos.height}px`
                this.$refs.input.style['opacity'] = '1'
            }
        },
        removeInputStyles(input) {
            input.style.left = null
            input.style.top = null
            input.style.width = null
            input.style.height = null
            input.style.opacity = null
        },
    },
}
</script>

<template>
    <div :class="{ hasFocus: focusNumber }">
        <BaseLabel :field="field" />
        <div :class="$style.wrapper">
            <input
                ref="input"
                type="tel"
                pattern="[0-9]*"
                autocomplete="off"
                :class="$style.input"
                @keydown.prevent="onKeydown"
                @keyup.prevent="onKeyup"
                @blur="onBlur"
                @focus="onFocus"
            />
            <FormTelNumbers
                :amount="3"
                :shift="0"
                :number="number"
                :active-number="activeNumber"
                :focus-number="focusNumber"
            />
            <FormTelNumbers
                :amount="3"
                :shift="2"
                :number="number"
                :active-number="activeNumber"
                :focus-number="focusNumber"
            />
            <FormTelNumbers
                :amount="2"
                :shift="5"
                :number="number"
                :active-number="activeNumber"
                :focus-number="focusNumber"
            />
            <FormTelNumbers
                :amount="2"
                :shift="7"
                :number="number"
                :active-number="activeNumber"
                :focus-number="focusNumber"
            />
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    position: relative;
    display: flex;
    margin-top: 8px;
}
.input {
    @include taphighlight;
    @include unselectable;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: transparent;
    border: 0;
    outline: none;
    opacity: 0.01;
}
</style>
